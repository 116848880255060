.Dropdown {
  &__trigger {
    padding: 0;
    background-color: transparent;
    border-color: transparent;
    border: none;
    cursor: pointer;
  }
  &__menu {
    background: #fff;
    border: 1px solid #ebeaeb;
    border-radius: 10px;
    overflow: hidden;
    z-index: 3;
    animation-duration: 0.25s;
    white-space: nowrap;
    color: #8d89a3;

    &--up {
      bottom: 100% !important;
    }

    &--left {
      right: unset;
      left: 0;
    }

    &--right {
      right: 0;
    }
  }
}
