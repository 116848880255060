.Switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.25s;
    border-radius: 40px;
    background-color: #dde2e5;
    border: 1px solid #dde2e5;

    &:before {
      position: absolute;
      content: '';
      height: 16px;
      width: 16px;
      left: 1px;
      bottom: 1px;
      background-color: #fff;
      transition: 0.25s;
      border-radius: 50%;
    }
  }

  input:checked ~ &__slider:before {
    background-color: #fff;
  }

  input:disabled ~ &__slider:before {
    background-color: #fff;
  }

  input:checked + &__slider {
    background-color: var(--bs-primary);
    border: 1px solid var(--bs-primary);
  }

  input:not(:checked):disabled ~ &__slider:before {
    background-color: #fff;
    opacity: 0.7;
  }

  input:not(:checked):disabled + &__slider {
    background-color: #d1cfdc;
    border: 1px solid #d1cfdc;
  }

  input:disabled + &__slider {
    background-color: #dde2e5;
    border: 1px solid #dde2e5;
  }

  input:checked + &__slider:before {
    transform: translateX(100%);
  }

  &--disabled {
    .Switch__slider {
      background-color: #d1cfdc;
      border: 1px solid #d1cfdc;
      cursor: not-allowed;
    }
  }
}
