.Footer {
  font-family: 'Poppins', sans-serif;
  height: 135px;
  background: #100840;
  color: #fff;
  padding: 0 50px;
  a {
    color: #fff;
  }
}
