.Tile {
  $base: &;

  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(226, 225, 226, 0.74);
  border-radius: 10px;
  border: 3px solid #ebeaeb;
  transition: border-color 0.25s;

  &:hover {
    border-color: #5959e3;
  }

  min-height: 348px;

  &--sm {
    min-height: 185px;

    #{$base}__imageContainer {
      width: 185px;
    }
    #{$base}__content {
      padding: 15px 15px 0 50px;
    }
    #{$base}__title {
      font-size: 25px;
      line-height: 39px;
      margin-top: -10px;
    }

    #{$base}__text {
      font-size: 19px;
      line-height: 18px;
    }

    #{$base}__content {
      padding: 15px 15px 0 50px;
    }
  }

  &--debt {
    #{$base}__content {
      padding-top: 98px;
      padding-left: 50px;
      padding-bottom: 68px;
    }
  }

  &--debt#{$base}--sm {
    #{$base}__content {
      padding-top: 43px;
      padding-left: 40px;
      padding-bottom: 0;
    }
  }

  &__imageContainer {
    width: 348px;
  }

  &__content {
    padding: 30px 30px 0 50px;
  }

  &__title {
    font-size: 38px;
    color: #100840;
    line-height: 37px;
  }

  &__text {
    font-size: 24px;
    line-height: 37px;
  }

  &__icon {
    margin-left: auto;
    width: 35px;
    height: 35px;
  }

  &__collapseContent {
    max-height: 0;
    transition: max-height 0.25s, opacity 0.25s;
    overflow: hidden;
  }

  &__description {
    background: rgba(235, 234, 235, 0.4);
    padding: 50px;
  }

  &__label {
    font-size: 18px;
    line-height: 17px;
  }
  &__value {
    font-size: 24px;
    line-height: 37px;

    &--green {
      color: #75ce8c;
    }
    &--purple {
      color: #5959e3;
    }
    &--red {
      color: #f05a62;
    }
  }

  &__badge {
    font-size: 16px;
    line-height: 19px;
    margin-top: 2px;

    &--top {
      margin-top: 0px;
    }
  }

  &__actions {
    padding: 46px 50px;
  }
}
