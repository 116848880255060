.Select {
  .Dropdown__trigger {
    width: 100%;
  }

  .Dropdown__menu {
    width: 100%;
    padding: 20px !important;
  }

  &__item {
    cursor: pointer;
  }
}
