.Checkbox {
  $base: &;
  &__label {
    user-select: none;
    color: #8d89a3;
  }

  &__input {
    flex-shrink: 0;
    border: 1px solid #ebeaeb;
    background: #ebeaeb;
    width: 21px;
    height: 21px;
    &:focus {
      outline: none;
    }

    svg {
      top: 0;
      left: 0;
    }
  }

  &__input--checkbox {
    border: 1px solid #ebeaeb;
    border-radius: 4px;
    display: flex;
    svg {
      width: 21px;
      height: 21px;
    }
  }

  &__container {
    align-items: start;
    position: relative;
    cursor: pointer;
    input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
    }
    input:focus ~ #{$base}__input--checkbox {
      border: 1px solid #ebeaeb;
    }
    input:focus ~ #{$base}__input--radio {
      border: 1px solid #ebeaeb;
    }

    input:disabled ~ #{$base}__input--checkbox,
    input:disabled ~ #{$base}__input--radio {
      border: 1px solid #ebeaeb;
    }

    input:disabled ~ #{$base}__label {
      color: #929f9a;
    }

    input:checked ~ #{$base}__input--checkbox {
      background: #ebeaeb;
      border: 1px solid #ebeaeb;
    }

    input:disabled ~ #{$base}__input--checkbox {
      background: #929f9a;
      border: 1px solid #929f9a;
    }

    input:checked ~ #{$base}__input--radio {
      position: relative;
      border: 1px solid #ebeaeb;
      background-color: #ebeaeb;
    }

    input:checked ~ #{$base}__input--radio:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #fff;
    }
  }

  &__error {
    #{$base}__input {
      border: 1px solid #ff3d3d;
    }
  }

  &__input--radio {
    border-radius: 50%;
  }
}
