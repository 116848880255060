.Input {
  color: #fff;
  width: 100%;

  &__input {
    background-color: #fff;
    border: 1px solid #ebeaeb;
    border-radius: 10px;
    padding: 13px 20px;
    color: #8d89a3;
    outline: none;
    transition: border-color 0.25s;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;

    &::placeholder {
      color: #8d89a3;
    }

    &--error {
      border: 1px solid #f05a62;
    }
  }

  &__error {
    font-size: 14px;
    line-height: 21px;
    color: #f05a62;
  }
}
