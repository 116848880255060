.Login {
  &__btn {
    color: #100840;
    height: 48px;
    padding: 0 16px;
    border: 1px solid var(--bs-blue);
    max-width: 238px;
    border-radius: 4px;

    &--fb {
      svg {
        margin-right: 13px;
      }
    }
    &--apple {
      border: 1px solid #000;
      svg {
        margin-right: 10px;
      }
    }
    &--google {
      svg {
        margin-right: 8px;
      }
    }
  }

  &__signUp {
    color: #5959e3;
    &:hover {
      color: #353192;
    }
  }
}

.Input--Login {
  max-width: 260px !important;
}
