@font-face {
  src: url(../../common/src/fonts/SofiaSans-Regular.ttf);
  font-family: 'Sofia Sans';
  font-style: normal;
  font-weight: 400;
}
@font-face {
  src: url(../../common/src/fonts/SofiaSans-Bold.ttf);
  font-family: 'Sofia Sans';
  font-style: normal;
  font-weight: 700;
}
@font-face {
  src: url(../../common/src/fonts/SofiaSans-SemiBold.ttf);
  font-family: 'Sofia Sans';
  font-style: normal;
  font-weight: 600;
}
@font-face {
  src: url(../../common/src/fonts/SofiaSans-ExtraBold.ttf);
  font-family: 'Sofia Sans';
  font-style: normal;
  font-weight: 800;
}
@font-face {
  src: url(../../common/src/fonts/SofiaSans-Black.ttf);
  font-family: 'Sofia Sans';
  font-style: normal;
  font-weight: 900;
}

@import 'reset.scss';

:root {
  --bs-blue: #1877f2;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #f05a62;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #75ce8c;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #8d89a3;
  --bs-gray-dark: #343a40;
  --bs-primary: #5959e3;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #100840;
  --bs-font-sans-serif: 'Poppins', sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
}

$enable-negative-margins: true;
$spacer: 1.25rem;
$grid-gutter-width: 1.875rem;

@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';
@import '../../../node_modules/bootstrap/scss/bootstrap-utilities';
@import '../../../node_modules/bootstrap/scss/spinners';
@import '../../../node_modules/bootstrap/scss/grid';

body {
  font-family: 'Sofia Sans', sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: #100840;
}

.text {
  &--14 {
    font-size: 14px;
    line-height: 21px;
  }

  &--24 {
    font-size: 24px;
    line-height: 30px;
  }

  &-dark {
    color: var(--bs-dark);
  }

  &-gray {
    color: var(--bs-gray);
  }

  &-green {
    color: var(--bs-green);
  }
  &-red {
    color: var(--bs-red);
  }
  &-primary {
    color: var(--bs-primary) !important;
  }
}

.fw-800 {
  font-weight: 800;
}

.transition-color {
  transition: color 0.25s;
}

.transition-bg {
  transition: background-color 0.25s;
}

.cursor-pointer {
  cursor: pointer;
}

a {
  @extend .transition-color;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
  animation-duration: 200ms;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 200ms;
}

.spinner-border {
  animation: var(--bs-spinner-animation-speed) linear infinite spinthiscircle !important;
}

@keyframes spinthiscircle {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}