.Calendar {
  max-width: 282px;
  &__row {
    grid-template-columns: repeat(7, 40px);
  }

  &__cell {
    font-size: 12px;
    line-height: 15px;
    padding: 10px;
    user-select: none;
    cursor: pointer;
    color: var(--bs-dark);
    &--day {
      &:not(:last-child) {
        border-right: 1px solid #dfdfdf;
      }
    }

    &--selected {
      background-color: var(--bs-primary);
      color: #fff;
    }

    &--inactive {
      color: var(--bs-gray-200);
      cursor: not-allowed;
    }
    height: 40px;
  }

  &__dayOfWeek {
    font-weight: 600;
  }

  &__grid {
    border: 1px solid #dfdfdf;
  }

  &__week {
    &:not(:last-child) {
      border-bottom: 1px solid #dfdfdf;
    }
  }

  &__header {
    svg {
      path {
        fill: var(--bs-dark);
      }
    }
  }
}
