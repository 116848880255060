.YourPlan {
  &__tab {
    min-width: 236px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: none;
    padding: 7.5px 10px;
    color: #ffffff;
    background-color: #d1cfdc;
    svg {
      width: 40px;
      height: 40px;
      path {
        stroke: #ffffff;
        fill: #ffffff;
        transition: fill 0.25s, stroke 0.25s;
      }
    }
    transition: background-color 0.25s, color 0.25s;

    &--active {
      border: 1px solid #ebeaeb;
      background-color: #ffffff;
      padding: 16px 10px;
      color: #8d89a3;
      svg {
        path {
          fill: #8d89a3;
          stroke: #8d89a3;
        }
      }
    }
  }

  &__circle {
    width: 9px;
    height: 9px;
    background-color: #d1cfdc;
    transition: background-color 0.25s;

    &--active {
      background-color: #100840;
    }
  }
}
