.DatePicker {
  width: 100%;

  .Dropdown__trigger {
    background-color: #fff;
    border: 1px solid #ebeaeb;
    border-radius: 10px;
    padding: 13px 20px;
    box-sizing: border-box;
    color: #264f3f;
    line-height: 24px;
    background-color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      transition: transform 0.25s;
    }

    &--disabled {
      cursor: not-allowed;
      color: var(--bs-gray-500);
      background-color: #fff;
      border-color: var(--bs-gray-500);
      svg {
        path {
          fill: var(--bs-gray-500);
        }
      }
    }
  }

  &--error {
    .Dropdown__trigger {
      border-color: #f05a62 !important;
    }
  }

  &__error {
    font-size: 14px;
    line-height: 21px;
    color: #f05a62;
  }
}
