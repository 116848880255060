.bg {
  background: url('./images/bg.png') center / cover no-repeat ;
}
.bg-dashboard {
  background: url('./images/bg-dashboard.png') top / cover no-repeat ;
}
.bg-upgrade {
  background: url('./images/bg-upgrade.png') top / cover no-repeat ;
}
.bg-wantmore {
  background: url('./images/wave.svg') top / cover no-repeat ;
}