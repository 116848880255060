.DatePickerCalendar {
  padding: 8px 12px;
  max-width: 310px;
  box-sizing: border-box;
  border-radius: 8px;

  &__arrow {
    &--left {
      transform: rotate(90deg);
    }
    &--right {
      transform: rotate(-90deg);
    }
  }
}
